import React from 'react'
import Helmet from 'react-helmet'
import { SITE_TITLE, MENUS } from 'src/constants'
import { stripLinkToIndex } from 'src/commonFunctions'
import ArticleLayout from 'components/ArticleLayout'
import NovelArticle from 'components/NovelArticle'
import Footer from 'components/Footer'

export default ({ pageContext }) => {
  const {
    type,
    articleBaseInfo,
    recommendArticles,
    novelContent,
    novelsWithImages,
    sharePath,
    linkToIndex,
    adbanners,
  } = pageContext
  const { title, date, slug, tags, ogpSettings, isOfficial } = articleBaseInfo
  const linktoindex = stripLinkToIndex(linkToIndex)

  return (
    <>
      <Helmet title={`${title} | ${SITE_TITLE}`} />
      <ArticleLayout
        type={type}
        articleBaseInfo={articleBaseInfo}
        recommendArticles={recommendArticles}
        sharePath={sharePath}
        linkToIndex={linkToIndex}
        adbanners={adbanners}
      >
        <NovelArticle
          genre={MENUS.NOVEL}
          title={title}
          tags={tags}
          date={date}
          ogp={ogpSettings.ogp}
          linktoindex={linktoindex}
          slug={slug}
          novelContent={novelContent}
          novelsWithImages={novelsWithImages}
          isOfficial={isOfficial}
          sharePath={sharePath}
        />
      </ArticleLayout>
      <Footer tags={pageContext.tags} />
    </>
  )
}
